admin.customer_gift_email = {

    selectAllEmails: function () {
        $("#check_all_customer_gift_emails").on('click', function () {
            $('.customer_gift_email_checkbox').prop('checked', this.checked);
        });
    },

    createCsvReport: function () {
        $('#export-customer-gift-email-csv-button').on('click', function () {
            $('.csv-report-flash').remove();
            var adminEmail = $(this).attr('data-admin-email'),
                customerGiftEmailIds = [];
            $.each($(".customer_gift_email_checkbox:checked"), function () {
                customerGiftEmailIds.push($(this).val());
            });
            if (customerGiftEmailIds.length === 0) {gi
                customerGiftEmailIds = $('section#customer-gift-email-section').attr('data-customer-gift-email-ids').split(',');
            }

            if (customerGiftEmailIds.length === 0) {
                admin.base.addFlash("warning csv-report-flash", "You need to select some customer gift emails before requesting a CSV report.");
                admin.base.scrollTop("main");

            } else {
                $.ajax(
                    {
                        url: '/customer_gift_email_csvs',
                        type: 'POST',
                        data: {'customer_gift_email_ids': customerGiftEmailIds},
                        dataType: 'json',
                        success: function (data) {
                            admin.base.addFlash('success csv-report-flash', 'Successfully created the CSV report. Please check ' + adminEmail + ' for a link to the file.');
                            admin.base.scrollTop("main");
                            $('.customer_gift_email_checkbox').prop('checked', false);
                        },
                        error: function (xhr, evt, status) {
                            admin.base.addFlash('warning csv-report-flash', 'Unable to create the CSV report. Please try again.');
                            admin.base.scrollTop("main");
                        }
                    });
            }
            return false;
        });
    },
}