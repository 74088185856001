admin.email_template = {

    sendTestEmailModal: function () {
        $('body').on('click', '.send-test-email-template-modal', function () {
            var $this = $(this),
                url = $this.attr('href');

            $.ajax(
                {
                    url: url,
                    type: "GET",
                    dataType: "json",
                    success: function (data) {
                        $('#send-test-email-template-wrapper').html(data.modal);
                        admin.base.openModal('#test-email-template-form');
                    },
                    error: function (xhr, evt, status) {
                        admin.base.addFlash("danger", "Something went wrong. Please try again.");
                        admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    },

    sendTestEmail: function () {
        $('body').on('submit', '#send-test-email-template', function () {
            var $this = $(this),
                url = $this.attr('action');

            $.ajax(
                {
                    url: url,
                    type: 'POST',
                    data: $this.serialize(),
                    dataType: 'json',
                    success: function (data) {
                        $('#test-email-template-form').modal('hide');
                        admin.base.addFlash("success", "Successfully sent a test email for " + data.email_template.subject + ".");
                        admin.base.scrollTop("main");
                    },
                    error: function (xhr, evt, status) {
                        $('#test-email-template-form #errors ul').html("<li><i class='fa fa-times-circle'></i>Invalid email, please try again.</li>");
                        $('#test-email-template-form .json-error-wrapper').show();
                        admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    }
}