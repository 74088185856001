admin.base = {
    jsonErrors: function (xhr, evt, status, form) {
        var content, value, _i, _len, _ref, $this, contentWrapper;
        $this = form;
        contentWrapper = $this.find('.json-error-wrapper');
        content = contentWrapper.find('#errors');
        content.find('ul').empty();
        _ref = $.parseJSON(xhr.responseText).errors;
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
            value = _ref[_i];
            content.show().find('ul').append('<li><i class="fa fa-times-circle"></i>' + value + '</li>');
        }
        contentWrapper.show();
    },

    mdbootstrap: function () {
        $('.mdb-select').materialSelect();
        $(".button-collapse").sideNav();
        $('.field_with_errors + label').addClass('active');
        $('.mdb-select').click(e => e.stopPropagation());

        $('.table-responsive').on('shown.bs.dropdown', function (e) {
            var t = $(this),
                m = $(e.target).find('.dropdown-menu'),
                tb = t.offset().top + t.height(),
                mb = m.offset().top + m.outerHeight(true),
                d = 20; // Space for shadow + scrollbar.
            if (t[0].scrollWidth > t.innerWidth()) {
                if (mb + d > tb) {
                    t.css('padding-bottom', ((mb + d) - tb));
                }
            } else {
                t.css('overflow', 'visible');
            }
        }).on('hidden.bs.dropdown', function () {
            $(this).css({
                'padding-bottom': '',
                'overflow': ''
            });
        });

        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        })
    },

    openModal: function (target) {
        $(target).modal(
            {
                backdrop: 'static',
                keyboard: false
            });
    },

    string_parameterize: function (string) {
        return string.trim().toLowerCase().replace(/[^a-zA-Z0-9 -]/, "").replace(/\s/g, "-");
    },

    initDragDropFileUpload: function (target_elem) {
        target_elem.file_upload();
    },

    dateRangePicker: function (elem_name) {
        moment.defaultFormat = 'DD/MM/YYYY';
        var startEndParam = admin.base.getUrlVars()[elem_name]
        if (startEndParam === undefined) {
            var start = moment(moment(), moment.defaultFormat);
            var end = moment(moment(), moment.defaultFormat);
        } else {
            var startParam = startEndParam.split('-')[0];
            var start = moment(decodeURIComponent(startParam.replace(/\+/g, '%20')).trim(), moment.defaultFormat);

            var endParam = startEndParam.split('-')[1];
            var end = moment(decodeURIComponent(endParam.replace(/\+/g, '%20')).trim(), moment.defaultFormat);
        }

        function cb(start, end) {
            $('#' + elem_name + '.dateranger span').html(start + ' - ' + end);
        }

        $('#' + elem_name + '.dateranger').daterangepicker({
            startDate: start,
            endDate: end,
            opens: 'left',
            cancelLabel: 'Clear',
            locale: {
                format: moment.defaultFormat
            },
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        }, cb);

        cb(start, end);
    },

    getUrlVars: function () {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value;
        });
        return vars;
    },

    addFlash: function (alertClass, message) {
        $('#admin-wrapper').prepend('<div class="mt-4 alert alert-' + alertClass + '" role="alert">' + message + '</div>');
    },

    scrollTop: function (element) {
        $([document.documentElement, document.body]).animate({
            scrollTop: $(element).offset().top
        }, 1000);
    },

    addCustomFlash: function (element, alertClass, message) {
        $(element).prepend('<div class="mt-4 alert alert-' + alertClass + '" role="alert">' + message + '</div>');
    },

    datePicker: function () {
        $('.datepicker').pickadate({
            clear: 'Clear selection',
            close: 'Cancel',
            format: 'dd/mm/yyyy',
            formatSubmit: 'yyyy-mm-dd',
            hiddenName: true,
        })
    },

    initCharCounter: function (formElem, counterElem, count) {
        $(formElem).keyup(function () {
            $(counterElem).html("<small><i>Characters left: " + (count - $(this).val().length) + "</i></small>");
        });
    },

    initSummernote: function () {
        $('.summernote').summernote();
    },

    changeSearchLimitParam: function () {
        $('#limit_param').on('change', function () {
            window.location = admin.base.updateQueryStringParameter(window.location.href, 'per_page', this.value);
            $('#search_limit').val(this.value);
        });
    },

    updateQueryStringParameter: function (uri, key, value) {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
            return uri.replace(re, '$1' + key + "=" + value + '$2');
        } else {
            return uri + separator + key + "=" + value;
        }
    },

    newWYSIWYG: function (element_id) {
        $(element_id).mdbWYSIWYG();
    },

    dateTimePicker: function (elem_name) {
        var date = new Date();
        date.setDate(date.getDate());

        $('#' + elem_name + '.datetimepicker').datetimepicker(
            {
                format: "m/d/Y H:i",
                minDate: date,
                step: 10,
            });
    },
}