admin.customer = {

    selectAllCustomers: function () {
        $("#check_all_customers").on('click', function () {
            $('.customer_checkbox').prop('checked', this.checked);
        });
    },

    createCsvReport: function () {
        $('#export-csv-button').on('click', function () {
            $('.csv-report-flash').remove();
            var adminEmail = $(this).attr('data-admin-email'),
                customerIds = [];
            $.each($(".customer_checkbox:checked"), function () {
                customerIds.push($(this).val());
            });
            if (customerIds.length === 0) {
                customerIds = $('section#customer-section').attr('data-customer-ids').split(',');
            }

            if (customerIds.length === 0) {
                admin.base.addFlash("warning csv-report-flash", "You need to select some customers before requesting a CSV report.");
                admin.base.scrollTop("main");

            } else {
                $.ajax(
                    {
                        url: '/customer_csvs',
                        type: 'POST',
                        data: {'customer_ids': customerIds},
                        dataType: 'json',
                        success: function (data) {
                            admin.base.addFlash('success csv-report-flash', 'Successfully created the CSV report. Please check ' + adminEmail + ' for a link to the file.');
                            admin.base.scrollTop("main");
                            $('.customer_checkbox').prop('checked', false);
                        },
                        error: function (xhr, evt, status) {
                            admin.base.addFlash('warning csv-report-flash', 'Unable to create the CSV report. Please try again.');
                            admin.base.scrollTop("main");
                        }
                    });
            }
            return false;
        });
    },
}