$(document).ready(function () {
    admin.base.mdbootstrap();
    admin.base.initDragDropFileUpload($('.file-upload'));
    admin.base.dateRangePicker("start_end_range");
    admin.base.dateRangePicker("charged_start_end_range");
    admin.base.dateRangePicker("dispatched_start_end_range");
    admin.base.dateRangePicker("last_placed_start_end_range");
    admin.base.datePicker();
    admin.base.initCharCounter("#product_description", "#product-description-char-count", 250);
    admin.base.initSummernote();
    admin.base.changeSearchLimitParam();

    admin.admin_account.changeAdminRole();

    admin.product.create();
    admin.product.edit();
    admin.product.updateNameToSlug();
    admin.product.formatSlug();
    admin.product.initImagesSortable();
    admin.product.sortProductsModal();
    admin.product.imageVariantsModal();
    admin.product.updateImageVariants();

    admin.category.formatSlug();
    admin.category.updateNameToSlug();
    admin.category.sortCategoryProductsModal();

    admin.inventory.newStock();
    admin.inventory.createStock();

    admin.stock_movement.createStockCollection();

    admin.order.createCsvReport()
    admin.order.createAddressCsv();
    admin.order.newRefund();
    admin.order.createRefund();
    admin.order.selectAllOrders();
    admin.order.createSingleEmailPdf();
    admin.order.createCombinedEmailPdf();

    admin.referral.completeReward();

    admin.discount.changeDiscountType();
    admin.discount.changeFilterType();
    admin.discount.archive();

    admin.order_bump.changeOverridePrice();
    admin.order_bump.archive();
    admin.order_bump.unarchive();

    admin.abandon_email.changeDelayType();
    admin.abandon_email.sendTestEmailModal();
    admin.abandon_email.sendTestEmail();
    admin.abandon_email.publish();
    admin.abandon_email.unpublish();

    admin.report.createVariantCsvReport();

    admin.landing_page.formatSlug();
    admin.landing_page.updateNameToSlug();
    admin.landing_page.archive();
    admin.landing_page.unarchive();

    admin.review.edit();
    admin.review.update();
    admin.review.archive();
    admin.review.unarchive();

    admin.customer.selectAllCustomers();
    admin.customer.createCsvReport();

    admin.newsletter_email.selectAllEmails();
    admin.newsletter_email.createCsvReport();

    admin.customer_gift_email.selectAllEmails();
    admin.customer_gift_email.createCsvReport();

    admin.email_template.sendTestEmailModal();
    admin.email_template.sendTestEmail();
});

$(document).ajaxComplete(function() {
    $("input[type=text]").trigger("focusin");
    $("input[type=text]").trigger("blur");
    admin.product.initProductsSortable();
    admin.category.initCategoryProductsSortable();
});