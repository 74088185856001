admin.abandon_email = {

    changeDelayType: function () {
        $('.abandon-email-form select#abandon_email_delay_type').on('change', function () {
            if (this.value === 'dynamic') {
                $('#delay-wrapper').show();
                $('.abandon-email-form #abandon_email_delay_value').val('1');
            } else {
                $('#delay-wrapper').hide();
                $('.abandon-email-form #abandon_email_delay_value').val('0');
                $('.abandon-email-form #abandon_email_delay_identifier').val('minute');
            }
        });
    },

    sendTestEmailModal: function () {
        $('body').on('click', '.send-test-email-modal', function () {
            var $this = $(this),
                url = $this.attr('href');

            $.ajax(
                {
                    url: url,
                    type: "GET",
                    dataType: "json",
                    success: function (data) {
                        $('#send-test-email-wrapper').html(data.modal);
                        admin.base.openModal('#test-abandon-email-form');
                    },
                    error: function (xhr, evt, status) {
                        admin.base.addFlash("danger", "Something went wrong. Please try again.");
                        admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    },

    sendTestEmail: function () {
        $('body').on('submit', '#send-test-email', function () {
            var $this = $(this),
                url = $this.attr('action');

            $.ajax(
                {
                    url: url,
                    type: 'POST',
                    data: $this.serialize(),
                    dataType: 'json',
                    success: function (data) {
                        $('#test-abandon-email-form').modal('hide');
                        admin.base.addFlash("success", "Successfully sent a test email for " + data.abandon_email.subject + ".");
                        admin.base.scrollTop("main");
                    },
                    error: function (xhr, evt, status) {
                        $('#test-abandon-email-form #errors ul').html("<li><i class='fa fa-times-circle'></i>Invalid email, please try again.</li>");
                        $('#test-abandon-email-form .json-error-wrapper').show();
                        admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    },

    publish: function () {
        $('body').on('click', '.publish-abandon-email', function () {
            var $this = $(this),
                url = $this.attr('href');

            $.ajax(
                {
                    url: url,
                    type: 'POST',
                    dataType: 'json',
                    success: function (data) {
                        $('tr.abandon-email-' + data.id).replaceWith(data.row);
                        admin.base.addFlash("success", "Successfully published an abandoned email template.");
                        admin.base.scrollTop("main");
                    },
                    error: function (xhr, evt, status) {
                        admin.base.addFlash("danger", "Failed to publish abandoned email template, please try again.");
                        admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    },

    unpublish: function () {
        $('body').on('click', '.unpublish-abandon-email', function () {
            var $this = $(this),
                url = $this.attr('href');

            $.ajax(
                {
                    url: url,
                    type: 'POST',
                    dataType: 'json',
                    success: function (data) {
                        $('tr.abandon-email-' + data.id).replaceWith(data.row);
                        admin.base.addFlash("success", "Successfully unpublished an abandoned email template.");
                        admin.base.scrollTop("main");
                    },
                    error: function (xhr, evt, status) {
                        admin.base.addFlash("danger", "Failed to unpublish abandoned email template, please try again.");
                        admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    }
}