admin.inventory =
    {
        newStock: function () {
            $('body').on('click', '#add-stock-inventory', function () {
                var url = $(this).attr('href');
                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#add-stock-modal').html(data.modal);
                            admin.base.openModal('#stock-movement-form');

                        }
                    });
                return false;
            });
        },

        createStock: function () {
            $('body').on('submit', '#create-stock-movement', function () {
                var $this = $(this),
                    url = $this.attr('action');

                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $this.serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#stock-movement-form').modal('hide');
                            $('#stock-table tbody tr td:last-child').removeClass('bg-success white-text');
                            $('#stock-table tbody').prepend(data.row);
                        },
                        error: function (xhr, evt, status) {
                            admin.base.jsonErrors(xhr, evt, status, $this);
                        }
                    });
                return false;
            });
        }
    }