admin.report = {
    createVariantCsvReport: function () {
        $('#export-variant-csv-button').on('click', function () {
            $('.variant-csv-report-flash').remove();
            var adminEmail = $(this).attr('data-admin-email'),
                orderItemIds = $('#sales-variants-report').attr('data-order-item-ids').split(',');
            if (orderItemIds.length === 0) {
                admin.base.addFlash("warning csv-report-flash", "You need to have some orders before requesting a Sales by Variant CSV report.");
                admin.base.scrollTop("main");
            } else {
                $.ajax(
                    {
                        url: '/reports/variant_csvs',
                        type: 'POST',
                        data: {'order_item_ids': orderItemIds},
                        dataType: 'json',
                        success: function (data) {
                            admin.base.addFlash('success variant-csv-report-flash', 'Successfully created the Sales by Variant CSV report. Please check ' + adminEmail + ' for a link to the file.');
                            admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            admin.base.addFlash('warning variant-csv-report-flash', 'Unable to create the Sales by Variant CSV report. Please try again.');
                            admin.base.scrollTop("main");
                        }
                    });
            }
            return false;
        });
    },
}