window.admin = {
    base: {},
    product: {},
    stock_movement: {},
    inventory: {},
    order: {},
    referral: {},
    discount: {},
    order_bump: {},
    abandon_email: {},
    admin_account: {},
    report: {},
    landing_page: {},
    review: {},
    customer: {},
    newsletter_email: {},
    customer_gift_email: {},
    email_template: {},
    category: {}
}