admin.order = {
    createCsvReport: function () {
        $('#export-csv-button').on('click', function () {
            $('.csv-report-flash').remove();
            var adminEmail = $(this).attr('data-admin-email'),
                orderIds = [];

            $.each($(".order_checkbox:checked"), function () {
                orderIds.push($(this).val());
            });
            if (orderIds.length === 0) {
                admin.base.addFlash("warning csv-report-flash", "You need to select some orders before requesting a CSV report.");
                admin.base.scrollTop("main");
            } else {
                $.ajax(
                    {
                        url: '/order_csvs',
                        type: 'POST',
                        data: {'order_ids': orderIds},
                        dataType: 'json',
                        success: function (data) {
                            admin.base.addFlash('success csv-report-flash', 'Successfully created the CSV report. Please check ' + adminEmail + ' for a link to the file.');
                            admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            admin.base.addFlash('warning csv-report-flash', 'Unable to create the CSV report. Please try again.');
                            admin.base.scrollTop("main");
                        }
                    });
            }
            return false;
        });
    },

    createAddressCsv: function () {
        $('#export-address-csv-button').on('click', function () {
            var adminEmail = $(this).attr('data-admin-email'),
                orderIds = [];

            $('.address-csv-flash').remove();
            $.each($(".order_checkbox:checked"), function () {
                orderIds.push($(this).val());
            });
            if (orderIds.length === 0) {
                admin.base.addFlash("warning csv-report-flash", "You need to select some orders before requesting an Address CSV.");
                admin.base.scrollTop("main");
            } else {
                $.ajax(
                    {
                        url: '/address_csvs',
                        type: 'POST',
                        data: {'order_ids': orderIds},
                        dataType: 'json',
                        success: function (data) {
                            admin.base.addFlash('success address-csv-flash', 'Successfully created the Order Address CSV. Please check ' + adminEmail + ' for a link to the file.');
                            admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            admin.base.addFlash('warning address-csv-flash', 'Unable to create the Order Address CSV. Please try again.');
                            admin.base.scrollTop("main");
                        }
                    });
            }
            return false;
        });
    },

    newRefund: function () {
        $('body').on('click', '.create-order-refund', function () {
            var $this = $(this),
                url = $this.attr('href');

            $.ajax(
                {
                    url: url,
                    type: "GET",
                    dataType: "json",
                    success: function (data) {
                        $('#order-refund-modal').html(data.modal);
                        admin.base.openModal('#order-refund-form');
                        $('#order-refund-form .mdb-select').materialSelect();
                    }
                });
            return false;
        });
    }
    ,

    createRefund: function () {
        $('body').on('submit', '.order-refund-form', function () {
            var $this = $(this),
                url = $this.attr('action');

            $.ajax(
                {
                    url: url,
                    type: 'POST',
                    data: $this.serialize(),
                    dataType: 'json',
                    success: function (data) {
                        $('#order-refund-form').modal('hide');
                        $('#refunds-table tbody').prepend(data.row);
                        admin.base.addFlash("success", "Successfully created a refund for this order.");
                        admin.base.scrollTop("main");
                    },
                    error: function (xhr, evt, status) {
                        admin.base.jsonErrors(xhr, evt, status, $this);
                    }
                });
            return false;
        });
    }
    ,

    selectAllOrders: function () {
        $("#check_all_orders").on('click', function () {
            $('.order_checkbox').prop('checked', this.checked);
        });
    },

    createSingleEmailPdf: function () {
        $('#create-email-pdf').on('click', function () {
            var orderId = $(this).attr('data-order-id');
            $('.email-pdf-flash').remove();

            $.ajax(
                {
                    url: '/order_email_pdfs',
                    type: 'POST',
                    data: {'order_ids': [orderId]},
                    dataType: 'json',
                    success: function (data) {
                        admin.base.addFlash("success email-pdf-flash", "Successfully created an Email PDF. Please check your admin email for a link to the file.");
                        admin.base.scrollTop("main");
                    },
                    error: function (xhr, evt, status) {
                        admin.base.addFlash("warning email-pdf-flash", "Unable to create an Email PDF. Please try again.");
                        admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    },


    createCombinedEmailPdf: function() {
        $('#export-pdfs-button').on('click', function () {
            $('.export-pdfs-flash').remove();

            var orderIds = [];
            $.each($(".order_checkbox:checked"), function () {
                orderIds.push($(this).val());
            });
            if (orderIds.length === 0) {
                admin.base.addFlash("warning export-pdfs-flash", "You need to select some orders before requesting a combined PDF invoice.");
                admin.base.scrollTop("main");
            } else {
                $.ajax(
                    {
                        url: '/order_email_pdfs',
                        type: 'POST',
                        data: {'order_ids': orderIds},
                        dataType: 'json',
                        success: function (data) {
                            admin.base.addFlash("success export-pdfs-flash", "Successfully created a combined PDF invoice. Please check your admin email for a link to the file.");
                            admin.base.scrollTop("main");
                            $('.order_checkbox').prop('checked', false);
                            $('#check_all_orders').prop('checked', false);
                        },
                        error: function (xhr, evt, status) {
                            admin.base.addFlash("warning export-pdfs-flash", "Unable to create a combined PDF invoice. Please try again.");
                            admin.base.scrollTop("main");
                        }
                    });
            }
            return false;
        });
    },
}