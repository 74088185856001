admin.category = {

    updateNameToSlug: function () {
        var nameInput = document.getElementById('category_name'),
            slugInput = document.getElementById('category_slug'),
            slugLabel = $('label[for="category_slug"]');

        // Init a timeout variable to be used below
        var timeout = null;

        // Listen for keystroke events
        if (typeof (nameInput) != 'undefined' && nameInput != null) {
            nameInput.onkeyup = function (e) {

                // Clear the timeout if it has already been set.
                // This will prevent the previous task from executing
                // if it has been less than <MILLISECONDS>
                clearTimeout(timeout);

                // Make a new timeout set to go off in 800ms
                timeout = setTimeout(function () {
                    if (slugInput.value === "") {
                        slugInput.value = admin.base.string_parameterize(nameInput.value);
                        slugLabel.addClass('active');
                    }
                }, 2000);
            };
        }
    },

    formatSlug: function () {
        var slugInput = document.getElementById('category_slug');

        // Init a timeout variable to be used below
        var timeout = null;

        // Listen for keystroke events
        if (typeof (slugInput) != 'undefined' && slugInput != null) {
            slugInput.onkeyup = function (e) {

                // Clear the timeout if it has already been set.
                // This will prevent the previous task from executing
                // if it has been less than <MILLISECONDS>
                clearTimeout(timeout);

                // Make a new timeout set to go off in 800ms
                timeout = setTimeout(function () {
                    slugInput.value = admin.base.string_parameterize(slugInput.value);
                }, 500);
            };
        }
    },

    initCategoryProductsSortable: function () {
        $('#sortable-category-product-list').sortable({
            placeholder: "ui-state-highlight",
            forcePlaceholderSize: true,
            tolerance: "pointer",
            update: function (e, ui) {
                $.ajax(
                    {
                        url: $(this).data("url"),
                        type: "PATCH",
                        data: $(this).sortable('serialize'),
                        success: function (data) {
                        },
                        error: function (xhr, evt, status) {
                            admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            admin.base.scrollTop("main");
                        }
                    });
            }
        });
        $("#sortable-product-list").disableSelection();
    },

    sortCategoryProductsModal: function () {
        $('body').on('click', '.reorder-category-products', function () {
            var url = $(this).attr('href');

            $.ajax(
                {
                    url: url,
                    type: "GET",
                    dataType: "json",
                    success: function (data) {
                        $('#sort-category-products-wrapper').html(data.modal);
                        admin.base.openModal('#sort-category-products-form');
                    }
                });
            return false;
        });
    }
}