admin.referral = {
    completeReward: function () {
        $('body').on('click', '.complete-referral-reward', function () {
            var url = $(this).attr('href'),
                $this = $(this),
                rewardId = $this.attr('data-referral-reward-id');

            $.ajax(
                {
                    url: url,
                    type: 'PATCH',
                    dataType: 'json',
                    success: function (data) {
                        $('tr.referral-reward-' + rewardId).replaceWith(data.row);
                        admin.base.addCustomFlash("#rewards-wrapper", "success", "Successfully marked a reward as completed.");
                        admin.base.scrollTop("#rewards-wrapper");
                    },
                });
            return false;
        });
    }
}