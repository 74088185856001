window.jQuery = $;
window.$ = $;

require("@rails/ujs").start();
require("jquery-ui-dist/jquery-ui");

require("moment-timezone");
require("daterangepicker");
require("summernote/summernote-bs4");

require("mdbootstrap/popper.min");
require("mdbootstrap/bootstrap");
require("mdbootstrap/mdb");
require("mdbootstrap/addons/mdb-file-upload");

require("admin/_admin");
require("admin/admin.base");
require("admin/admin.product");
require("admin/admin.stock_movement");
require("admin/admin.inventory");
require("admin/admin.order");
require("admin/admin.referral");
require("admin/admin.discount");
require("admin/admin.order_bump");
require("admin/admin.abandon_email");
require("admin/admin.admin_account");
require("admin/admin.report");
require("admin/admin.landing_page");
require("admin/admin.review");
require("admin/admin.customer");
require("admin/admin.newsletter_email");
require("admin/admin.customer_gift_email");
require("admin/admin.email_template");
require("admin/admin.category");
require("admin/admin.ready");