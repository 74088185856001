admin.stock_movement =
    {
        addFields: function (content) {
            var new_id = new Date().getTime();
            var regexp = new RegExp("variant_stock_movements", "g")
            $('#stock-movements-fields').append(content.replace(regexp, new_id));
            $('#'+ new_id + '_attributes_'+ new_id +'_variant_id').materialSelect();
            $('#'+ new_id + '_attributes_'+ new_id +'_quantity').focus();
        },

        removeFields: function (link) {
            if ($('#stock-movements-fields .fields:not(.deleted)').length > 1) {
                $(link).prev("input[type=hidden]").val("1");
                $(link).closest(".fields").addClass('deleted').hide();
            }
        },

        createStockCollection: function () {
            $("body").on("submit", '#new_stock_movements', function () {
                var url = $(this).attr('action'),
                    $this = $(this);
                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $(this).serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $this[0].submit();
                        },
                        error: function (xhr, evt, status) {
                            admin.base.jsonErrors(xhr, evt, status, $this);
                        }
                    });
                return false;
            });
        }
    }
