admin.review =
    {
        edit: function ()
        {
            $('body').on('click', '.edit-review', function () {
                var $this = $(this),
                    url = $this.attr('href');

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#edit-review-modal').html(data.modal);
                            admin.base.openModal('#edit-review-form');
                            $('#edit-review-modal .mdb-select').materialSelect();
                            $('#edit-review-modal .mdb-select').click(e => e.stopPropagation());
                        },
                        error: function (xhr, evt, status) {
                            admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        update: function () {
            $("body").on("submit", '#edit-review', function () {
                var url = $(this).attr('action'),
                    $this = $(this);
                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        data: $(this).serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#edit-review-form').modal('hide');
                            $('tr#review-' + data.id + ' .publish-status').text(data.publish_status);
                            admin.base.addFlash("success", "Successfully updated a review.");
                        },
                        error: function (xhr, evt, status) {
                            admin.base.jsonErrors(xhr, evt, status, $this);
                        }
                    });
                return false;
            });
        },

        archive: function () {
            $('body').on('click', '.archive-review', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    reviewId = $this.attr('data-review-id'),
                    reviewRow = $('tr#review-' + reviewId);


                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            reviewRow.replaceWith(data.row);
                            admin.base.addFlash("success", "Successfully archived a review.");
                            admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        unarchive: function()
        {
            $('body').on('click', '.unarchive-review', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    reviewId = $this.attr('data-review-id'),
                    reviewRow = $('tr#review-' + reviewId);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            reviewRow.replaceWith(data.row);
                            admin.base.addFlash("success", "Successfully unarchived a review.");
                            admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        }
    }