admin.order_bump =
    {
        changeOverridePrice: function () {
            $('#product_order_bump_override_price').change(function () {
                if ($(this).val() === 'enabled') {
                    $('#order-bump-quantity-wrapper').show();
                    $('#order-bump-price-wrapper').show();
                } else {
                    $('#order-bump-quantity-wrapper').hide();
                    $('#order-bump-price-wrapper').hide();
                }
            });
        },

        archive: function () {
            $('body').on('click', '.archive-order-bump', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    orderBumpId = $this.attr('data-order-bump-id'),
                    orderBumpRow = $('tr.order-bump-' + orderBumpId);


                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            orderBumpRow.replaceWith(data.row);
                            admin.base.addFlash("success", "Successfully archived an order bump.");
                            admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        unarchive: function()
        {
            $('body').on('click', '.unarchive-order-bump', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    orderBumpId = $this.attr('data-order-bump-id'),
                    orderBumpRow = $('tr.order-bump-' + orderBumpId);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            orderBumpRow.replaceWith(data.row);
                            admin.base.addFlash("success", "Successfully unarchived an order bump.");
                            admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        }
    }