admin.discount =
    {

        changeDiscountType: function () {
            $("body").on('change', 'select#discount_discount_type', function () {
                var val = $(this).val();
                if (val === 'free_shipping') {
                    $('#discount-value-wrapper').hide();
                    $('#discount-value-wrapper input').val(1);
                } else {
                    $('#discount-value-wrapper').show();
                }
            });
        },

        addRule: function (link, association, content) {
            var new_id = new Date().getTime(),
                regexp = new RegExp("new_" + association, "g");
            $("#discount-rules").append(content.replace(regexp, new_id));
            var selectTags = $('div[data-fields-id="' + new_id + '"] select');
            selectTags.materialSelect()
            selectTags.each(function () {
                $(this).click(e => e.stopPropagation());
            });
        },

        removeRule: function (link) {
            $(link).prev("input[type=hidden]").val("1");
            $(link).closest(".fields").hide();
        },

        changeFilterType: function () {
            $('body').on('change', 'select.discount-rule-filter-type', function () {
                var $this = $(this),
                    parentWrapper = $this.parent().parent().parent();
                admin.discount.updateConditionSelect(parentWrapper, this.value);
                if (this.value === 'cart_item') {
                    parentWrapper.find('.discount-rule-filter-value-wrapper').show();
                    parentWrapper.find('.discount-rule-filter-value-wrapper div label').text('Quantity');
                    parentWrapper.find('.discount-rule-product-id-wrapper').show();
                } else if (this.value === 'cart_subtotal') {
                    parentWrapper.find('.discount-rule-filter-value-wrapper').show();
                    parentWrapper.find('.discount-rule-filter-value-wrapper div label').text('Cart value');
                    parentWrapper.find('.discount-rule-product-id-wrapper').hide();
                }
            });
        },

        updateConditionSelect: function (parentWrapper, selectValue) {
            var conditions = window.discount_rule_conditions[selectValue],
                selectElem = parentWrapper.find('select.discount-rule-condition');
            selectElem.empty();
            for (var i = 0; i < conditions.length; i++) {
                selectElem.append('<option value="' + conditions[i][0] + '">' + conditions[i][1] + '</option>');
            }
        },

        archive: function()
        {
            $('body').on('click', '.archive-discount', function () {
                var $this = $(this),
                    url = $(this).attr('href'),
                    discountId = $this.attr('data-discount-id');

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            $('tr#discount-' + discountId).remove(data.row);
                            $('#view-discount-archive-button').remove();
                            admin.base.addFlash("success", "Successfully archived a discount.");
                            admin.base.scrollTop("main");
                        },
                    });
                return false;
            });
        }
    }