admin.product = {
    create: function () {
        $("body").on("submit", '#new_product', function () {
            var url = $(this).attr('action'),
                $this = $(this);
            $.ajax(
                {
                    url: url,
                    type: 'POST',
                    data: $(this).serialize(),
                    dataType: 'json',
                    success: function (data) {
                        $this[0].submit();
                    },
                    error: function (xhr, evt, status) {
                        admin.base.jsonErrors(xhr, evt, status, $this);
                        $([document.documentElement, document.body]).animate({
                            scrollTop: $("#add-product-header").offset().top
                        }, 1000);
                    }
                });
            return false;
        });
    },

    edit: function () {
        $("body").on("submit", '.edit_product', function () {
            var url = $(this).attr('action'),
                $this = $(this);
            $.ajax(
                {
                    url: url,
                    type: 'PATCH',
                    data: $(this).serialize(),
                    dataType: 'json',
                    success: function (data) {
                        $this[0].submit();
                    },
                    error: function (xhr, evt, status) {
                        admin.base.jsonErrors(xhr, evt, status, $this);
                        $([document.documentElement, document.body]).animate({
                            scrollTop: $("#edit-product-header").offset().top
                        }, 1000);
                    }
                });
            return false;
        });
    },

    updateNameToSlug: function () {
        var nameInput = document.getElementById('product_name'),
            slugInput = document.getElementById('product_slug'),
            slugLabel = $('label[for="product_slug"]');

        // Init a timeout variable to be used below
        var timeout = null;

        // Listen for keystroke events
        if (typeof (nameInput) != 'undefined' && nameInput != null) {
            nameInput.onkeyup = function (e) {

                // Clear the timeout if it has already been set.
                // This will prevent the previous task from executing
                // if it has been less than <MILLISECONDS>
                clearTimeout(timeout);

                // Make a new timeout set to go off in 800ms
                timeout = setTimeout(function () {
                    if (slugInput.value === "") {
                        slugInput.value = admin.base.string_parameterize(nameInput.value);
                        slugLabel.addClass('active');
                    }
                }, 2000);
            };
        }
    },

    formatSlug: function () {
        var slugInput = document.getElementById('product_slug');

        // Init a timeout variable to be used below
        var timeout = null;

        // Listen for keystroke events
        if (typeof (slugInput) != 'undefined' && slugInput != null) {
            slugInput.onkeyup = function (e) {

                // Clear the timeout if it has already been set.
                // This will prevent the previous task from executing
                // if it has been less than <MILLISECONDS>
                clearTimeout(timeout);

                // Make a new timeout set to go off in 800ms
                timeout = setTimeout(function () {
                    slugInput.value = admin.base.string_parameterize(slugInput.value);
                }, 500);
            };
        }
    },

    initImagesSortable: function () {
        $('#product-images').sortable({
            placeholder: "ui-state-highlight",
            forcePlaceholderSize: true,
            tolerance: "pointer",
            update: function (e, ui) {
                $("ul#product-images li").each(function (index) {
                    $(this).find('.product-image-position').val(index);
                });
            }
        });
        $("#product-images").disableSelection();
    },

    addImage: function (link, association, content) {
        var new_id = new Date().getTime(),
            regexp = new RegExp("new_" + association, "g");
        $('ul#product-images').append(content.replace(regexp, new_id));
        var productImageFileUpload = $('#product_images_attributes_' + new_id + '_imageable_type').parent().find('.file-upload-wrapper input');
        admin.base.initDragDropFileUpload(productImageFileUpload);
    },

    removeImage: function (link) {
        $(link).prev("input[type=hidden]").val("1");
        $(link).closest(".fields").hide();
    },

    addVariant: function (link, association, content) {
        var new_id = new Date().getTime(),
            regexp = new RegExp("new_" + association, "g");
        $('#product-variants').append(content.replace(regexp, new_id));
        $('#product_variants_attributes_' + new_id + '_locale').materialSelect();
        $('#product_variants_attributes_' + new_id + '_locale').click(e => e.stopPropagation());
        $('#product_variants_attributes_' + new_id + '_stock').focus();
        $('#product_variants_attributes_' + new_id + '_price').focus();
    },

    removeVariant: function (link) {
        $(link).prev("input[type=hidden]").val("1");
        $(link).closest(".fields").hide();
    },

    addContent: function (link, association, content) {
        var new_id = new Date().getTime(),
            regexp = new RegExp("new_" + association, "g");
        $('#product-contents').append(content.replace(regexp, new_id));
        $('#product_contents_attributes_' + new_id + '_product_grid').materialSelect();
        $('#product_contents_attributes_' + new_id + '_product_grid').click(e => e.stopPropagation());
        $('#product_contents_attributes_' + new_id + '_content').summernote();
    },

    removeContent: function (link) {
        $(link).prev("input[type=hidden]").val("1");
        $(link).closest(".fields").hide();
    },

    initProductsSortable: function () {
        $('#sortable-product-list').sortable({
            placeholder: "ui-state-highlight",
            forcePlaceholderSize: true,
            tolerance: "pointer",
            update: function (e, ui) {
                $.ajax(
                    {
                        url: $(this).data("url"),
                        type: "PATCH",
                        data: $(this).sortable('serialize'),
                        success: function (data) {
                            $('#products-table').html(data.table);
                        },
                        error: function (xhr, evt, status) {
                            admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            admin.base.scrollTop("main");
                        }
                    });
            }
        });
        $("#sortable-product-list").disableSelection();
    },

    sortProductsModal: function () {
        $('body').on('click', '#reorder-products', function () {
            var url = $(this).attr('href');

            $.ajax(
                {
                    url: url,
                    type: "GET",
                    dataType: "json",
                    success: function (data) {
                        $('#sort-products-wrapper').html(data.modal);
                        admin.base.openModal('#sort-products-form');
                    }
                });
            return false;
        });
    },

    imageVariantsModal: function()
    {
        $('body').on('click', '.edit-image-variants', function () {
            var url = $(this).attr('href');

            $.ajax(
                {
                    url: url,
                    type: "GET",
                    dataType: "json",
                    success: function (data) {
                        $('#image-variants-wrapper').html(data.modal);

                        $('#image-variants-form .mdb-select').materialSelect();
                        admin.base.openModal('#image-variants-form');
                    }
                });
            return false;
        });
    },

    updateImageVariants: function()
    {
        $("body").on("submit", '.update-image-variants', function () {
            var url = $(this).attr('action'),
                $this = $(this);

            $.ajax(
                {
                    url: url,
                    type: 'PATCH',
                    data: $(this).serialize(),
                    dataType: 'json',
                    success: function (data) {
                        $('#image-variants-form').modal('hide');
                    },
                    error: function (xhr, evt, status) {
                        admin.base.jsonErrors(xhr, evt, status, $this);
                    }
                });
            return false;
        });
    },

    addShipping: function (link, association, content) {
        var new_id = new Date().getTime(),
            regexp = new RegExp("new_" + association, "g");
        $('#new-product-shippings').append(content.replace(regexp, new_id));
        var countryIdSelect = $('#product_shippings_attributes_' + new_id + '_country_ids'),
            countryIdInput = $('#product_shippings_attributes_' + new_id + '_country_ids_input');
        countryIdInput.attr('name', 'product[shippings_attributes][][country_ids][' + new_id + ']');
        countryIdSelect.materialSelect();
        countryIdSelect.click(e => e.stopPropagation());
        $('#product_shippings_attributes_' + new_id + '_price').focus();
    },

    editShipping: function (link, association, content) {
        var new_id = new Date().getTime(),
            regexp = new RegExp("new_" + association, "g");
        $('#edit-product-shippings').append(content.replace(regexp, new_id));
        var countryIdSelect = $('#product_shippings_attributes_' + new_id + '_country_ids'),
            countryIdInput = $('#product_shippings_attributes_' + new_id + '_country_ids_input');
        countryIdInput.attr('name', 'product[shippings_attributes][][country_ids][' + new_id + ']');
        countryIdSelect.materialSelect();
        countryIdSelect.click(e => e.stopPropagation());
        $('#product_shippings_attributes_' + new_id + '_price').focus();
    },

    removeShipping: function (link) {
        $(link).prev("input[type=hidden]").val("1");
        $(link).closest(".fields").hide();
    }
}