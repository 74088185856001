admin.admin_account =
    {

        changeAdminRole: function()
        {
            $('#new_admin select#admin_role').on('change', function() {
                if (this.value == 'super') {
                    $('#admin-permissions-wrapper').hide();
                    $('#super-admin-save').show();
                    $('#admin-permissions-wrapper select#admin_admin_permission_ids').val('');
                } else {
                    $('#admin-permissions-wrapper').show();
                    $('#super-admin-save').hide();
                }
            });
        }
    }